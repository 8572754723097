import React, { useState, useEffect, useRef } from "react"
import { TransverseLoading } from "react-loadingg"
import { TimelineLite, TweenMax, Power3 } from "gsap"

import { Box, SiteName, Paragraph } from "../helpers/styledComponents"
import AnimatedSlider from "../components/utils/animatedSlider"
import Base from "../components/layout/base"
import theme from "../helpers/theme"
import { Expo } from "gsap/gsap-core"
import SEO from "../components/utils/seo"

const IndexPage = props => {
  let app = useRef(null)
  let content = useRef(null)
  let loader = useRef(null)
  let tl = new TimelineLite()

  useEffect(() => {
    tl.to(app, 0, { css: { visibility: "visible" } })
      .to(loader, 0, { css: { display: "none" } }, 3)
      .to(content, 0, { css: { display: "block" } })
      .from(
        content.firstElementChild,
        1.3,
        { y: 40, opacity: 0, ease: Expo.easeOut },
        "+=0.2"
      )
  })

  const images = props.data.allSanityPhoto.nodes.map(node => {
    return node.file.asset.fluid.src
  })

  return (
    <Base hideSideText={true} hideHeader={true} hideFooter={true}>
      <SEO title="Home" />
      <Box
        ref={el => (app = el)}
        css={{
          visibility: "hidden",
        }}
      >
        <Box
          background={theme.colors.black}
          height="100vh"
          width="100vw"
          m={0}
          ref={el => (loader = el)}
        >
          <TransverseLoading color={theme.colors.white} />
        </Box>
        <Box ref={el => (content = el)} css={{ display: "none" }}>
          <Box
            position="fixed"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            css={{
              top: "50%",
              transform: "translateY(-50%)",
              height: "60px",
            }}
          >
            <SiteName color={theme.colors.white} to="/">
              Jeremy Hsu
            </SiteName>
            <Paragraph as="a" color={theme.colors.white} href="/about">
              Continue to site >
            </Paragraph>
          </Box>
          <AnimatedSlider
            images={images}
            displacementImage={props.data.displacementImage.publicURL}
          />
        </Box>
      </Box>
    </Base>
  )
}

export const pageQuery = graphql`
  query IndexPageQuery {
    displacementImage: file(relativePath: { eq: "dmaps/512x512/clouds.jpg" }) {
      publicURL
    }
    allSanityPhoto(
      filter: { showOnHome: { eq: true } }
      sort: { order: ASC, fields: uniqueIdentifier }
    ) {
      nodes {
        file {
          asset {
            fluid(maxWidth: 3000) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`

export default IndexPage
