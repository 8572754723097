import React, { useEffect, useRef, useState } from "react"
import { Expo, Power2, TimelineMax, TweenMax } from "gsap"
import * as PIXI from "pixi.js"
import styled from "@emotion/styled"

import {
  Box,
  ButtonRow,
  Text,
  VerticalParagraph,
} from "../../helpers/styledComponents"
import theme from "../../helpers/theme"
import useInterval from "../../helpers/useInterval"

const AnimatedSlider = props => {
  const { images, displacementImage, showNavBtn } = props

  const options = {
    autoPlay: true,
    autoPlaySpeed: [10, 3],
    displaceScale: [300, 100],
    displaceScaleTo: [0, 0],
    displaceAutoFit: true,
    displacementCenter: true,
    interactive: true,
    interactionEvent: "click",
  }

  const [app, setApp] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [delay, setDelay] = useState(5000)
  let [displacementTexture, setDisplacementTexture] = useState(null)
  let [displacementSprite, setDisplacementSprite] = useState(null)
  const [displacementFilter, setDisplacementFilter] = useState(null) // displacement filter
  const [isPlaying, setIsPlaying] = useState(false)
  const [isSliderRunning, setIsSliderRunning] = useState(true)
  const [slideImages, setSlideImages] = useState([])
  let [ticker, setTicker] = useState(null)
  let [renderer, setRenderer] = useState(null)
  let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0
  let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0

  let mount = useRef(null)
  let slidesContainer = null

  const createSliderImageSprites = sprites => {
    const loader = new PIXI.Loader()
    sprites.forEach((sprite, index) => {
      loader.add(`image${index}`, sprite)
    })

    loader.on("complete", (loader, resources) => {
      Object.keys(resources).forEach((resource, index) => {
        const sprite = new PIXI.Sprite(resources[resource].texture)
        sprite.x = 0
        sprite.y = 0
        sprite.width = windowWidth
        sprite.height = windowHeight
        sprite.objectFit = "cover"

        if (typeof window !== "undefined" && window.innerWidth < 768) {
          sprite.width = 1200
        }
        if (index !== 0) {
          TweenMax.set(sprite, { alpha: 0 })
        }
        slidesContainer.addChild(sprite)
      })
    })

    loader.load()
  }

  const moveSlider = newIndex => {
    setIsPlaying(true)
    let baseTimeline = new TimelineMax({
      onComplete: () => {
        setIsPlaying(false)
      },
    })
    baseTimeline.clear()
    if (baseTimeline.isActive()) {
      return
    }
    setCurrentIndex(newIndex)
    baseTimeline
      .to(displacementFilter.scale, 1.5, {
        x: options.displaceScale[0],
        y: options.displaceScale[1],
        ease: Power2.easeOut,
      })
      .to(slideImages[currentIndex], 1.5, { alpha: 0, ease: Power2.easeOut }, 0)
      .to(slideImages[newIndex], 1, { alpha: 1, ease: Power2.easeOut }, 1)
      .to(
        displacementFilter.scale,
        1.5,
        {
          x: options.displaceScaleTo[0],
          y: options.displaceScaleTo[1],
          ease: Expo.easeOut,
        },
        0.8
      )
  }

  const onClickNext = event => {
    if (isPlaying) {
      return false
    }
    if (currentIndex >= 0 && currentIndex < slideImages.length - 1) {
      moveSlider(currentIndex + 1)
    } else {
      moveSlider(0)
    }
  }

  const onClickPrev = event => {
    if (isPlaying) {
      return false
    }
    if (currentIndex > 0 && currentIndex < slideImages.length) {
      moveSlider(currentIndex - 1)
    } else {
      moveSlider(slideImages.length - 1)
    }
  }

  useEffect(() => {
    let app = new PIXI.Application({
      resizeTo: window,
      sharedTicker: true,
    })
    setApp(app)

    mount.appendChild(app.view)

    // create a new container for image slides
    slidesContainer = new PIXI.Container()

    // add slides container to the stage
    app.stage.addChild(slidesContainer)

    // add displacement filter to stage
    let displacementTexture = new PIXI.Texture.from(displacementImage)
    setDisplacementTexture(displacementTexture)
    let displacementSprite = new PIXI.Sprite(displacementTexture)
    setDisplacementSprite(displacementSprite)
    displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT
    let filter = new PIXI.filters.DisplacementFilter(displacementSprite)
    setDisplacementFilter(filter)

    // set the filter to stage and set some default values for the animation
    app.stage.filters = [filter]
    filter.scale.x = 0
    filter.scale.y = 0

    setSlideImages(slidesContainer.children)

    // create image sprites for slider
    createSliderImageSprites(images)

    // autoplay
    if (options.autoPlay) {
      let tempTicker = PIXI.Ticker.shared
      tempTicker.autoStart = options.autoPlay

      let tempRenderer = new PIXI.autoDetectRenderer()
      tempTicker.add(delta => {
        displacementSprite.x += options.autoPlaySpeed[0] * delta
        displacementSprite.y += options.autoPlaySpeed[1]
        tempRenderer.render(app.stage)
      })
      setTicker(tempTicker)
      setRenderer(tempRenderer)
    }

    const handleResize = () => {
      if (typeof window !== "undefined") {
        slidesContainer.children.forEach((slide, index) => {
          slide.width = window.innerWidth
          slide.height = window.innerHeight

          if (window.innerWidth < 768) {
            slide.width = 1200
          }
        })
      }
    }

    window.addEventListener("resize", handleResize)
  }, [])

  useInterval(
    () => {
      onClickNext()
    },
    isSliderRunning ? delay : null
  )

  return (
    <Box ref={ref => (mount = ref)} height="100vh">
      {showNavBtn && (
        <>
          <NavBtn
            next={false}
            onClick={() => {
              setIsSliderRunning(false)
              onClickPrev()
            }}
          >
            <Text href="/">PREV</Text>
          </NavBtn>
          <NavBtn
            next={true}
            onClick={() => {
              setIsSliderRunning(false)
              onClickNext()
            }}
          >
            <Text href="/">NEXT</Text>
          </NavBtn>
        </>
      )}
      {!showNavBtn && (
        <>
          <NavBtnHolder>
            <Box>
              <Arrow
                onClick={() => {
                  setIsSliderRunning(false)
                  onClickPrev()
                }}
              >
                &#9650;
              </Arrow>
              {images.map((image, index) => {
                const isCurrentIndex = currentIndex === index
                return (
                  <Box key={`dot${index}`} css={{ zIndex: 10 }}>
                    <Dot
                      onClick={() => {
                        setIsSliderRunning(false)
                        moveSlider(index)
                      }}
                      opacity={isCurrentIndex ? 1 : undefined}
                    />
                  </Box>
                )
              })}
              <Arrow
                rotate={180}
                translateX={2}
                translateY={1}
                onClick={() => {
                  setIsSliderRunning(false)
                  onClickNext()
                }}
              >
                &#9650;
              </Arrow>
              <VerticalParagraph
                css={{
                  margin: 0,
                  cursor: "pointer",
                }}
                lineHeight={[0.5, 0.6]}
                fontSize={["0.7rem", "0.8rem"]}
                color={theme.colors.white}
                letterSpacing="2px"
                onClick={() => {
                  if (isSliderRunning) {
                    setIsSliderRunning(false)
                  } else {
                    setIsSliderRunning(true)
                  }
                }}
              >
                {isSliderRunning ? "PAUSE" : "PLAY"}
              </VerticalParagraph>
            </Box>
          </NavBtnHolder>
        </>
      )}
    </Box>
  )
}

const NavBtn = styled(ButtonRow)(props => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 10,
  display: "inline-block",
  right: props.next ? "2%" : "",
  left: props.next ? "" : "2%",
  p: {
    color: theme.colors.white,
  },
}))

const NavBtnHolder = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: 0,
  top: "50%",
  transform: "translateY(-50%)",
  paddingLeft: theme.spaces[4],
})

const Dot = styled(Box)(props => ({
  width: "5px",
  height: "5px",
  borderRadius: "50%",
  background: theme.colors.white,
  marginBottom: "20px",
  opacity: props.opacity || 0.4,
  cursor: "pointer",
}))

const Arrow = styled(Box)(props => ({
  cursor: "pointer",
  color: theme.colors.white,
  fontSize: theme.fontSizes[1],
  position: "relative",
  transform: `rotate(${props.rotate || 0}deg) translateX(${props.translateX ||
    -3}px) translateY(${props.translateY || 0}px)`,
  marginBottom: "20px",
}))

export default AnimatedSlider
